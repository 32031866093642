/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()
import Rails from '@rails/ujs';
Rails.start();
// require("channels")

import "bootstrap";
import "styles/application";

// For input fields with autocomplete functionality provided by select2 java script framework
import "select2";

// To copy text to clipboard (e.g. users access token)
import ClipboardJS from 'clipboard';

// Make file input fields work with bootstrap 4
// https://getbootstrap.com/docs/4.4/components/forms/#file-browser
import bsCustomFileInput from 'bs-custom-file-input'

// short-hand for '$(document).ready(function() {'
jQuery(function () {
  $('[data-toggle="tooltip"]').tooltip();

  $('#component_file, #company_company_logo').on('change',function() {
      $('.custom-file-label').html($(this).val());
  });

  // Make file input fields work with bootstrap 4
  // https://getbootstrap.com/docs/4.4/components/forms/#file-browser
  bsCustomFileInput.init()

  // Clipboard Tooltip
  $('.clipboard-btn').tooltip({
    trigger: 'click',
    placement: 'bottom'
  });

  function setTooltip(btn, message) {
    $(btn).tooltip('show')
      .attr('data-original-title', message)
      .tooltip('show');
  }

  function hideTooltip(btn) {
    setTimeout(function() {
      $(btn).tooltip('hide');
    }, 1000);
  }

  // Clipboard
  var clipboard = new ClipboardJS('.clipboard-btn');

  clipboard.on('success', function(e) {
    setTooltip(e.trigger, 'Copied!');
    hideTooltip(e.trigger);
  });

  clipboard.on('error', function(e) {
    setTooltip(e.trigger, 'Failed!');
    hideTooltip(e.trigger);
  });

  function humanFileSize(size) {
    var units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    var i = 0;

    while(size >= 1024) {
        size /= 1024;
        ++i;
    }

    return `${size.toFixed(1)} ${units[i]}`;
  }

  $('#zip_file').on('change', function() {
    var max_file_size = $('#plugin_max_file_size').val();

    if (max_file_size > 0 && this.files.length == 1) {
      var file_size = this.files[0].size;

      if (file_size > max_file_size) {
        this.setCustomValidity(`File size (${humanFileSize(file_size)}) must not exceed ${humanFileSize(max_file_size)}`);
        this.reportValidity();
      } else {
        this.setCustomValidity('');
      }
    }
  });

});

